import React from "react";
import { useLocation, Link } from "react-router-dom";

const ResetConfirmation = () => {
  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const email = query.get("email");
  return (
    <div className="font-inter flex flex-col items-center justify-center p-4 bg-white  max-w-lg mx-auto sm:p-3">
      {/* Logo */}
      <img
        src="/assets/images/Logo/Logo.png"
        alt="Logo"
        className="max-h-full w-auto"
      />

      {/* Main Content Container */}
      <div
        className="font-inter flex flex-col items-center justify-center gap-1 mt-8"
        style={{ marginTop: "100px" }}
      >
        {/* Key Icon */}
        <img
          src="/assets/images/Logo/emailboxicon.svg"
          alt="Email Icon"
          className="w-[56px] h-[56px] opacity-100"
        />

        <h2 className="font-inter text-[32px] font-semibold text-center text-black mb-2">
          Check your email
        </h2>
        <p className="font-inter text-[18px] text-center text-[#60768E] mb-4">
          We sent a password reset link to {email}.
        </p>
        <p className="font-inter text-[16px] text-center text-[#60768E]">
          Didn’t receive the email?{" "}
          <a href="/resend-email" className="text-[#003EDD] hover:underline">
            Click to resend
          </a>
        </p>
        <div className="mt-4 text-center">
          <Link to="/login" className="text-[#475467] hover:underline">
            ← Back to log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetConfirmation;
